<template>
  <!-- blogs start -->
  <div class="blogs-page">
    <PageLoader />
        <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/"  style="color: #782462 !important;">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/guides" style="color: #782462 !important;">Guides</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ bolgs.title }}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->
    <!-- blog wrapper start -->
    <div class="blog-area-wrapper pt-40 pb-70">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-3">
            <sideBarComp />
          </div> -->
          <div class="col-lg-12 order-first order-lg-last">
            <div class="blog-wrapper-inner">
              <div class="single-blogg-item mb-30">
                <div class="card mb-3">
                  <img :src="'https://app.contractsexpert.co.uk/storage/app/public/' + bolgs.img" class="card-img-top" alt="..." style="max-height: 25rem;">
                  <div class="card-body">
                    <div class="share_date clearfix">
                      <!-- <p class="card-text float-left"><small class="text-muted">Last updated {{bolgs.created_at | moment("ddd, DD-MM-YYYY")}}</small></p> -->
                      <div class="blog-social-sharing mb-40 float-right">
                        <social-sharing
                          :url="
                            'https://contractsexpert.co.uk/blogs/' +
                              this.$route.params.id
                          "
                          :title="bolgs.title"
                          :description="bolgs.desc"
                          quote=""
                          hashtags=""
                          twitter-user=""
                          inline-template
                        >
                          <div class="social_icons">
                            <network network="email">
                              <i class="fa fa-envelope"></i>
                            </network>
                            <network network="facebook">
                              <i class="fab fa-facebook-square"></i>
                            </network>
                            <network network="googleplus">
                              <i class="fab fa-google-play"></i>
                            </network>
                            <network network="linkedin">
                              <i class="fab fa-linkedin"></i>
                            </network>
                            <network network="pinterest">
                              <i class="fab fa-pinterest-square"></i>
                            </network>
                            <network network="twitter">
                              <i class="fab fa-twitter-square"></i>
                            </network>
                            <network network="whatsapp">
                              <i class="fab fa-whatsapp-square"></i>
                            </network>
                            <network network="skype">
                              <i class="fab fa-skype"></i>
                            </network>
                            <network network="sms">
                              <i class="fas fa-sms"></i>
                            </network>
                            <network network="telegram">
                              <i class="fab fa-telegram-plane"></i>
                            </network>
                            
                          </div>
                        </social-sharing>
                      </div>
                    </div>
                    <h1 class="card-title">{{ bolgs.title }}</h1>
                    <div class="card-text" v-html="bolgs.desc"></div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog wrapper end -->
  </div>
  <!-- blogs end -->
</template>
<script>
import sideBarComp from "@/views/pages/sidebars/index.vue";
import PageLoader from "@/components/units/PageLoader.vue";
export default {
  name: "newsCopm",
  components: {
    sideBarComp,
    PageLoader
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 4000
        },
        speed: 3000,
        direction: "horizontal",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      bolgs: []
    };
  },
  mounted() {
   window.scrollTo(0, 0)
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$http
        .get("blog/" + this.$route.params.id)
        .then(response => {
          this.bolgs = response.data.data;
          response => (this.bolgs = response.data.data);
          console.log(this.$route.params.id)
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
