<template>
  <div class="shop-sidebar-inner mb-30 pt-3">
    <div class="latest-blogg mb-40">
      <div class="section-title flash-title pb-1">
        <h5><span>Latest</span> Articles</h5>
      </div>
      <div class="swiper">
        <div v-for="(latestNews, i) in latestNews" :key="i">
          <div class="media m-2">
              <img class="align-self-center mr-3" style="width: 6rem;" :src="publicPath + latestNews.img" />
              <div class="media-body">
                <h5 class="mt-0" style="font-size: 14px;">
                  <router-link :to="'/news/' + latestNews.blogcategories.slug + '/' + latestNews.id" tag="a" style="color: inherit">
                    {{ latestNews.title }}
                  </router-link>
                </h5>
                <div v-html="getPragraphLimit(latestNews.desc, 200)"></div>
                <p class="card-text"><small class="text-muted">Posted at {{ latestNews.created_at | moment("ddd, DD-MM-YYYY") }}</small></p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flas-sale-area mb-30">
      <div class="section-title flash-title">
        <h3><span>Exclusive</span> Deals!</h3>
      </div>
      <swiper class="swiper product_offer_sec" :options="swiperOption">
        <swiper-slide
          v-for="(LatestProduct, i) in LatestProduct"
          :key="LatestProduct.slug + i"
          v-show="LatestProduct.data !== null"
        >
          <div class="product-item row mb-4 mr-2 p-0 pb-2">
            <div class="col-lg-12">
              <div class="row m-0">
                <div class="col-lg-4 overflow home-card p-0">
                  <div class="card  border-0 " style="height: 100%;">
                    <img
                      class="card-img m-auto img-width"
                      :src="
                        'https://app.contractsexpert.co.uk/storage/app/public/' +
                          LatestProduct.img
                      "
                      style="max-width: 6rem;"
                      alt
                    />

                    <div class="box-label cust_label">
                      <div
                        class="label-product label_new"
                        v-if="LatestProduct.pormoted == 1"
                        style="top: 130px; left: -5px;"
                      >
                        <span>Pormoted</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 z-index pl-2 pr-0">
                  <div
                    class="card-body d-block border-l pr-0 pl-1 content_list text-center"
                  >
                    <header class="header-card offer_title">
                      {{ LatestProduct.title }}
                    </header>

                    <ul class="list">
                      <li v-if="LatestProduct.data">
                        <a v-if="LatestProduct.data === '123456789'"
                          >Unlimited Of {{ LatestProduct.simNetwork }} Data
                        </a>
                        <a
                          v-else-if="
                            LatestProduct.data.length >= 4 &&
                              LatestProduct.data >= 1000
                          "
                          >{{ LatestProduct.data / 1000 }}GB Of
                          {{ LatestProduct.simNetwork }} Data</a
                        >
                        <a v-else>
                          {{ LatestProduct.data }}MB Of
                          {{ LatestProduct.simNetwork }} Data</a
                        >
                      </li>
                      <li v-if="LatestProduct.minutes">
                        <a v-if="LatestProduct.minutes === '123456789'"
                          >Unlimited Minutes</a
                        >
                        <a v-else> {{ LatestProduct.minutes }} Minutes</a>
                      </li>
                      <li v-if="LatestProduct.text">
                        <a v-if="LatestProduct.text === '123456789'"
                          >Unlimited Texts</a
                        >
                        <a v-else> {{ LatestProduct.text }} Texts</a>
                      </li>
                      <li v-if="LatestProduct.download">
                        <a v-if="LatestProduct.download === '123456789'"
                          >Unlimited Download</a
                        >
                        <a v-else> {{ LatestProduct.download }} Download</a>
                      </li>
                      <li v-if="LatestProduct.speed">
                        <a v-if="LatestProduct.speed === '123456789'"
                          >Unlimited Speed</a
                        >
                        <a v-else> {{ LatestProduct.speed }} Speed</a>
                      </li>
                      <li v-if="LatestProduct.channel">
                        <a v-if="LatestProduct.channel === '123456789'"
                          >Unlimited Channel</a
                        >
                        <a v-else> {{ LatestProduct.channel }} Channel</a>
                      </li>
                    </ul>
                    <span>
                      <span v-if="LatestProduct.upfront_cost === '123456789'">
                        <strong>£0</strong> Upfront cost
                      </span>
                      <span v-else>
                        <strong>£{{ LatestProduct.upfront_cost }}</strong>
                        Upfront cost
                      </span> </span
                    ><br />
                    <span
                      ><strong>£{{ LatestProduct.monthly_cost }}</strong> for
                      <strong>{{ LatestProduct.contract }}</strong> Months</span
                    ><br />
                  </div>
                  <div class="row">
                    <div
                      class="network_logo col-12 text-center"
                      v-if="LatestProduct.network.img"
                    >
                      <img
                        :src="
                          'https://app.contractsexpert.co.uk/storage/app/public/' +
                            LatestProduct.network.img
                        "
                        style="width: 3.5rem;"
                        alt
                      />
                    </div>
                    <div
                      class="reseller_logo col-12 text-center"
                      v-if="LatestProduct.reseller.img"
                    >
                      <img
                        :src="
                          'https://app.contractsexpert.co.uk/storage/app/public/' +
                            LatestProduct.reseller.img
                        "
                        style="width: 3.5rem;"
                        alt
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center m-0">
                <div class="action-links compare_action col-lg-3 pr-1 pl-1">
                  <a
                    @click="
                      compareF(LatestProduct.slug),
                        (isDisable = LatestProduct.id)
                    "
                    :class="
                      'm-auto ' + { disable: isDisable === LatestProduct.id }
                    "
                    title="Compare"
                  >
                    <i class="lnr lnr-sync"></i>
                  </a>
                </div>
                <div class="col-lg-8 pr-1 pl-1">
                  <a
                    :href="LatestProduct.link"
                    class="btn btn-outline-primary mt-2 btn-block btn_custom"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
              <div
                class="product-countdown"
                v-if="LatestProduct.endTo"
                :style="{
                  display: LatestProduct.endTo == 0 ? 'none' : 'block'
                }"
              >
                <Countdown
                  :deadline="LatestProduct.endTo | moment('MMMM DD, YYYY')"
                ></Countdown>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div> -->
  </div>
</template>
<script>
// import Countdown from "vuejs-countdown";
export default {
  name: "categories",
  // components: {
  //   Countdown
  // },
  data() {
    return {
      active_el: 0,
      latestNews: [],
      LatestProduct: [],
      blogCategory: [],
      publicPath:
        "https://admin.comparizone.co.uk/storage/app/public/"
    };
  },
  mounted() {
    this.getLatestNews();
    this.getLatestProduct();
    this.getblogCategory();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getLatestNews();
      this.getLatestProduct();
      this.getblogCategory();
    }
  },
  methods: {
    activate: function(el) {
      this.active_el = el;
    },
    getLatestNews() {
      this.loading = true;
      this.$urlmain
        .get("blog-latest.json")
        .then(response => {
          this.latestNews = response.data.data;
          response => (this.latestNews = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getLatestProduct() {
      this.loading = true;
      this.$urlmain
        .get("offers-latest.json")
        .then(response => {
          this.LatestProduct = response.data.data;
          response => (this.LatestProduct = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getblogCategory() {
      this.loading = true;
      this.$urlmain
        .get("blogCategory-popular.json")
        .then(response => {
          this.blogCategory = response.data.data;
          response => (this.blogCategory = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },
    getPragraphLimit(pragraph, num) {
      return pragraph > num ? pragraph.substring(0, num) + "..." : pragraph;
    }
  }
};
</script>
